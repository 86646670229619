import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu, MenuItem } from 'src/aiser/components/menu';
import { getNavbarMenuItems } from '../helpers/navbar_menu_items';
import { getSelectedMenu } from '../helpers/selected_menu';

const { Sider } = Layout;

interface NavbarProps {
  menuData: IAiserMenuItem[];
}

function Navbar(props: NavbarProps) {
  const { pathname, hash } = useLocation();

  const [collapsed, setCollapsed] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState<string[]>(['Home']);
  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);

  React.useEffect(() => {
    if (props.menuData.length > 0) {
      setMenuItems(getNavbarMenuItems(props.menuData));
    }
  }, [props.menuData]);

  React.useEffect(() => {
    setOpenKeys(getSelectedMenu(props.menuData, pathname, hash));
  }, [pathname, hash, props.menuData]);

  if (menuItems.length) {
    return (
      <Sider
        collapsible
        breakpoint="xxl"
        collapsed={collapsed}
        defaultCollapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        collapsedWidth="60px"
        theme="light"
        trigger={
          collapsed ? (
            <div style={{ fontSize: '16px' }}>
              <MenuUnfoldOutlined />
            </div>
          ) : (
            <div style={{ fontSize: '16px' }}>
              <MenuFoldOutlined />
            </div>
          )
        }
      >
        <Menu
          theme="light"
          mode="inline"
          // openKeys={openKeys}
          // onOpenChange={onOpenChange}
          selectedKeys={openKeys}
          onSelect={item => {
            setOpenKeys(item.keyPath);
          }}
          items={menuItems}
        />
      </Sider>
    );
  }

  return null;
}

export default Navbar;
