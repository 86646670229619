function getSelectedMenuByHash(menuData: IAiserMenuItem[], hash: string) {
  const selectedString: Array<string> = [];

  menuData.forEach(menu => {
    if (menu.childs && menu.childs.length > 0) {
      const childString = getSelectedMenuByHash(menu.childs!, hash);

      if (childString.length) {
        selectedString.push(...childString);
        selectedString.push(menu.name);
      }
    }

    if (menu.url === hash) {
      selectedString.push(menu.name);
    }
  });

  return selectedString;
}

function getSelectedMenuByPathname(
  menuData: IAiserMenuItem[],
  pathname: string,
) {
  const selectedString: Array<string> = [];

  menuData.forEach(menu => {
    if (menu.childs && menu.childs.length > 0) {
      const childString = getSelectedMenuByPathname(menu.childs!, pathname);

      if (childString.length) {
        selectedString.push(...childString);
        selectedString.push(menu.name);
      }
    }

    if (menu.url === pathname) {
      selectedString.push(menu.name);
    }
  });

  return selectedString;
}

export function getSelectedMenu(
  menuData: IAiserMenuItem[],
  pathname: string,
  hash: string,
) {
  let selectedString: Array<string> = [];

  selectedString = getSelectedMenuByHash(menuData, hash);

  if (selectedString.length === 0)
    selectedString = getSelectedMenuByPathname(menuData, pathname);

  return selectedString;
}
