/* eslint-disable theme-colors/no-literal-colors */
import { Layout } from 'antd';
import React from 'react';
import { BrandProps, NavBarProps } from 'src/types/bootstrapTypes';
import Header from './components/Header';
import Navbar from './components/Navbar';
import './style.css';

interface AiserLayoutProps {
  menuData: IAiserMenu;
  children: React.ReactNode;
  rightMenu: NavBarProps;
  environmentTag?: {
    color: string;
    text: string;
  };
  brand?: BrandProps;
  disabled?: boolean;
}

function AiserLayout(props: AiserLayoutProps) {
  if (props.disabled) {
    return <div className="site-layout-background">{props.children}</div>;
  }
  return (
    <Layout>
      <Header
        menuData={props.menuData || []}
        rightMenu={props.rightMenu}
        environmentTag={props.environmentTag}
        brand={props.brand!}
      />
      <Layout
        style={{
          minHeight: 'auto',
        }}
      >
        <div style={{ borderRight: '1px solid #f0f0f0', background: '#fff' }}>
          <Navbar menuData={props.menuData?.navbar_menu || []} />
        </div>
        <Layout>
          <Layout.Content className="content-container">
            <div className="site-layout-background">{props.children}</div>
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default AiserLayout;
