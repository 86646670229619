import { Global } from '@emotion/react';
import {
  SupersetClient,
  SupersetTheme,
  css,
  styled,
  supersetTheme,
  t,
  useTheme,
} from '@superset-ui/core';
import { Col, Grid, Row, message } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import { GenericLink } from 'src/components/GenericLink/GenericLink';
import Icons from 'src/components/Icons';
import { MainNav as Menu, MenuMode } from 'src/components/Menu';
import { Tooltip } from 'src/components/Tooltip';
import { BrandProps, NavBarProps } from 'src/types/bootstrapTypes';
import getBootstrapData from 'src/utils/getBootstrapData';
import { isFrontendRoute } from 'src/views/routes';
import { Typography } from 'src/components';
import Modal from 'src/components/Modal/Modal';
import RightMenu from './HeaderRightMenu';

const StyledHeader = styled.header`
  ${({ theme }) => `
      background-color: ${theme.colors.grayscale.light5};
      margin-bottom: 2px;
      z-index: 10;

      &:nth-last-of-type(2) nav {
        margin-bottom: 2px;
      }
      .caret {
        display: none;
      }
      .navbar-brand {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* must be exactly the height of the Antd navbar */
        min-height: 50px;
        padding: ${theme.gridUnit}px ${theme.gridUnit * 2}px ${
    theme.gridUnit
  }px ${theme.gridUnit * 4}px;
        max-width: ${theme.gridUnit * theme.brandIconMaxWidth}px;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .navbar-brand-text {
        border-left: 1px solid ${theme.colors.grayscale.light2};
        border-right: 1px solid ${theme.colors.grayscale.light2};
        height: 100%;
        color: ${theme.colors.grayscale.dark1};
        padding-left: ${theme.gridUnit * 4}px;
        padding-right: ${theme.gridUnit * 4}px;
        margin-right: ${theme.gridUnit * 6}px;
        font-size: ${theme.gridUnit * 4}px;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          max-width: ${theme.gridUnit * 58}px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @media (max-width: 1127px) {
          display: none;
        }
      }
      .main-nav .ant-menu-submenu-title > svg {
        top: ${theme.gridUnit * 5.25}px;
      }
      @media (max-width: 767px) {
        .navbar-brand {
          float: none;
        }
      }
      .ant-menu-horizontal .ant-menu-item {
        height: 100%;
        line-height: inherit;
      }
      .ant-menu > .ant-menu-item > a {
        padding: ${theme.gridUnit * 4}px;
      }
      @media (max-width: 767px) {
        .ant-menu-item {
          padding: 0 ${theme.gridUnit * 6}px 0
            ${theme.gridUnit * 3}px !important;
        }
        .ant-menu > .ant-menu-item > a {
          padding: 0px;
        }
        .main-nav .ant-menu-submenu-title > svg:nth-of-type(1) {
          display: none;
        }
        .ant-menu-item-active > a {
          &:hover {
            color: ${theme.colors.primary.base} !important;
            background-color: transparent !important;
          }
        }
      }
      .ant-menu-item a {
        &:hover {
          color: ${theme.colors.grayscale.dark1};
          background-color: ${theme.colors.primary.light5};
          border-bottom: none;
          margin: 0;
          &:after {
            opacity: 1;
            width: 100%;
          }
        }
      }
  `}
`;
const globalStyles = (theme: SupersetTheme) => css`
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-bottomLeft {
    border-radius: 0px;
  }
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light {
    border-radius: 0px;
  }
  .ant-menu-vertical > .ant-menu-submenu.data-menu > .ant-menu-submenu-title {
    height: 28px;
    i {
      padding-right: ${theme.gridUnit * 2}px;
      margin-left: ${theme.gridUnit * 1.75}px;
    }
  }
`;

const { useBreakpoint } = Grid;

const { SubMenu } = Menu;

interface MenuItemProps {
  menuData: IAiserMenu;
  environmentTag?: { color: string; text: string };
  rightMenu: NavBarProps;
  brand: BrandProps;
}

function Header(props: MenuItemProps) {
  const theme = useTheme();
  const screens = useBreakpoint();

  const [showMenu, setMenu] = React.useState<MenuMode>('horizontal');

  const [showSetDefaultModal, setShowSetDefaultModal] = React.useState(false);

  const brand = props.brand!;
  const handleSetDefaultWorkspace = () => {
    const { workspace_id } = props.menuData;

    if (workspace_id) {
      SupersetClient.post({
        endpoint: `/api/v1/default_workspace/set/`,
        body: JSON.stringify({
          workspace_id,
        }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(() => {
          window.location.reload();
        })
        .catch(err => {
          message.error('Something went wrong, please try again.');
        });
    }
  };

  const renderSubMenu = ({
    label,
    childs,
    url,
    index,
    isFrontendRoute,
  }: IAiserMenuItem & {
    index: number;
    isFrontendRoute: boolean;
  }) => {
    if (url && isFrontendRoute) {
      return (
        <Menu.Item key={label} role="presentation">
          <Link role="button" to={url}>
            {label}
          </Link>
        </Menu.Item>
      );
    }
    if (url) {
      return (
        <Menu.Item key={label}>
          <a href={url}>{label}</a>
        </Menu.Item>
      );
    }
    return (
      <SubMenu
        key={index}
        title={label}
        icon={showMenu === 'inline' ? <></> : <Icons.TriangleDown />}
      >
        {childs?.map((child: any | string, index1: number) => {
          if (typeof child === 'string' && child === '-' && label !== 'Data') {
            return <Menu.Divider key={`$${index1}`} />;
          }
          if (typeof child !== 'string') {
            return (
              <Menu.Item key={`${child.label}`}>
                {child.isFrontendRoute ? (
                  <Link to={child.url || ''}>{child.label}</Link>
                ) : (
                  <a href={child.url}>{child.label}</a>
                )}
              </Menu.Item>
            );
          }
          return null;
        })}
      </SubMenu>
    );
  };

  return (
    <StyledHeader className="top" id="main-menu" role="navigation">
      <Global styles={globalStyles(theme)} />
      <Modal
        title={t('Confirm Set Default')}
        show={showSetDefaultModal}
        onHandledPrimaryAction={handleSetDefaultWorkspace}
        primaryButtonName={t('Confirm')}
        onHide={() => {
          setShowSetDefaultModal(false);
        }}
      >
        <div
          style={{
            display: 'block',
            textAlign: 'center',
          }}
        >
          <Typography.Title level={4}>{t('Are You Sure?')}</Typography.Title>
          <Typography.Text>
            {t('This will set this workspace as your default workspace.')}
          </Typography.Text>
        </div>
      </Modal>
      <Row>
        <Col md={16} xs={24}>
          <Tooltip
            id="brand-tooltip"
            placement="bottomLeft"
            title={brand.tooltip}
            arrowPointAtCenter
          >
            {isFrontendRoute(window.location.pathname) ? (
              <GenericLink
                className="navbar-brand"
                to={props.menuData.branding_path}
              >
                <img src={brand.icon} alt={brand.alt} />
              </GenericLink>
            ) : (
              <a className="navbar-brand" href={brand.path}>
                <img src={brand.icon} alt={brand.alt} />
              </a>
            )}
          </Tooltip>
          <Menu mode={showMenu} data-test="navbar-top" className="main-nav">
            {props.menuData.left_menu?.map((item, index) => {
              const props = {
                index,
                ...item,
                isFrontendRoute: isFrontendRoute(item.url),
                childs: item.childs?.map(c => ({
                  ...c,
                  isFrontendRoute: isFrontendRoute(c.url),
                })),
              };

              return renderSubMenu(props);
            })}
            <div>
              {props.menuData.is_default_workspace !== null &&
                (props.menuData.is_default_workspace ? (
                  <Typography.Text
                    style={{ color: supersetTheme.colors.primary.base }}
                  >
                    <i
                      className="fa-regular fa-check-circle"
                      style={{ fontSize: '16px' }}
                    />{' '}
                    {t('Default')}
                  </Typography.Text>
                ) : (
                  <Button
                    onClick={() => {
                      setShowSetDefaultModal(true);
                    }}
                  >
                    {t('Set As Default')}
                  </Button>
                ))}
            </div>
          </Menu>
        </Col>
        <Col md={8} xs={24}>
          <RightMenu
            right_menu={props.menuData.right_menu}
            rightMenu={props.rightMenu}
            align={screens.md ? 'flex-end' : 'flex-start'}
            environmentTag={props.environmentTag}
            organization={props.menuData.organization}
            subscription={props.menuData.current_subscription}
          />
        </Col>
      </Row>
    </StyledHeader>
  );
}

export default Header;
