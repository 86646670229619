import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from 'src/aiser/components/menu';
import { isFrontendRoute } from 'src/views/routes';

export function getNavbarMenuItems(props: IAiserMenuItem[]): MenuItem[] {
  const menu: Array<MenuItem> = [];

  props.forEach((item: IAiserMenuItem) => {
    const data: MenuItem = {
      key: item.name!,
      label: isFrontendRoute(item.url) ? (
        <Link to={item.url || ''}>{item.label}</Link>
      ) : item.url ? (
        <a href={item.url || ''}>{item.label}</a>
      ) : (
        item.label
      ),
      icon: <i className={`fa ${item.icon}`} />,
      children: item.childs?.length
        ? getNavbarMenuItems(item.childs?.map((child: IAiserMenuItem) => child))
        : undefined,
    };

    menu.push(data);
  });

  return menu;
}
