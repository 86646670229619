/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  SupersetTheme,
  css,
  getExtensionsRegistry,
  styled,
  t,
  useTheme,
} from '@superset-ui/core';
import Icons from 'src/components/Icons';
import Label from 'src/components/Label';
import { MainNav as Menu } from 'src/components/Menu';
import { Tooltip } from 'src/components/Tooltip';
import { MenuObjectChildProps, NavBarProps } from 'src/types/bootstrapTypes';
import LanguagePicker from 'src/views/components/LanguagePicker';
import { isFrontendRoute } from 'src/views/routes';
import Button from 'src/components/Button';
import Descriptions, { DescriptionsProps } from 'antd/lib/descriptions';
import moment from 'moment';

const extensionsRegistry = getExtensionsRegistry();

const versionInfoStyles = (theme: SupersetTheme) => css`
  padding: ${theme.gridUnit * 1.5}px ${theme.gridUnit * 4}px
    ${theme.gridUnit * 4}px ${theme.gridUnit * 7}px;
  color: ${theme.colors.grayscale.base};
  font-size: ${theme.typography.sizes.xs}px;
  white-space: nowrap;
`;

const styledDisabled = (theme: SupersetTheme) => css`
  color: ${theme.colors.grayscale.base};
  backgroundColor: ${theme.colors.grayscale.light2}};
  .ant-menu-item:hover {
    color: ${theme.colors.grayscale.base};
    cursor: default;
  }
`;

const StyledDiv = styled.div<{ align: string }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }) => align};
  align-items: center;
  margin-right: ${({ theme }) => theme.gridUnit}px;
  .ant-menu-submenu-title > svg {
    top: ${({ theme }) => theme.gridUnit * 5.25}px;
  }
`;

const StyledAnchor = styled.a`
  padding-right: ${({ theme }) => theme.gridUnit}px;
  padding-left: ${({ theme }) => theme.gridUnit}px;
`;

const tagStyles = (theme: SupersetTheme) => css`
  color: ${theme.colors.grayscale.light5};
  font-weight: bold;
`;

const styledChildMenu = (theme: SupersetTheme) => css`
  &:hover {
    color: ${theme.colors.primary.base} !important;
    cursor: pointer !important;
  }
`;

const { SubMenu } = Menu;

interface Props {
  align: string;
  right_menu: IAiserMenuItem[];
  rightMenu: NavBarProps;
  environmentTag?: {
    text: string;
    color: string;
  };
  organization?: IAiserMenuOrganization;
  subscription?: IAiserMenuCurrentSubscription;
}

const RightMenu = ({
  align,
  environmentTag,
  right_menu,
  rightMenu,
  organization,
  subscription,
}: Props) => {
  const navbarRight = rightMenu;
  const tooltipText = t(
    "Enable 'Allow file uploads to database' in any database's settings",
  );

  // const buildMenuItem = (item: MenuObjectChildProps) =>
  //   item.disable ? (
  //     <Menu.Item key={item.name} css={styledDisabled}>
  //       <Tooltip placement="top" title={tooltipText}>
  //         {item.label}
  //       </Tooltip>
  //     </Menu.Item>
  //   ) : (
  //     <Menu.Item key={item.name} css={styledChildMenu}>
  //       {item.url ? <a href={item.url}> {item.label} </a> : item.label}
  //     </Menu.Item>
  //   );

  const RightMenuExtension = extensionsRegistry.get('navbar.right');

  const SubscriptionTooltip = ({ children }: any) => (
    <Tooltip
      color="white"
      title={
        <div>
          <Descriptions
            labelStyle={{
              fontWeight: 'bold',
              fontSize: '14px',
            }}
            contentStyle={{
              fontSize: '14px',
            }}
          >
            <Descriptions.Item label={t('Duration')} span={3}>
              {`${subscription?.duration} ${subscription?.duration_unit}`}
            </Descriptions.Item>
            <Descriptions.Item label={t('Start')} span={3}>
              {moment(subscription?.start_date).format('DD/MM/YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label={t('Expire')} span={3}>
              {moment(subscription?.end_date).format('DD/MM/YYYY')}
            </Descriptions.Item>
          </Descriptions>
        </div>
      }
    >
      {children}
    </Tooltip>
  );

  const renderSubMenu = ({
    label,
    childs,
    url,
    index,
    isFrontendRoute,
  }: IAiserMenuItem & {
    index: number;
    isFrontendRoute: boolean;
  }) => {
    if (url && isFrontendRoute) {
      return (
        <Menu.Item key={label} role="presentation">
          <Link role="button" to={url}>
            {label}
          </Link>
        </Menu.Item>
      );
    }
    if (url) {
      return (
        <Menu.Item key={label}>
          <a href={url}>{label}</a>
        </Menu.Item>
      );
    }
    return (
      <SubMenu
        key={index}
        title={label}
        icon={<Icons.TriangleDown iconSize="xl" />}
      >
        {childs?.map((child: any | string, index1: number) => {
          if (typeof child === 'string' && child === '-' && label !== 'Data') {
            return <Menu.Divider key={`$${index1}`} />;
          }
          if (typeof child !== 'string') {
            return (
              <Menu.Item key={`${child.label}`}>
                {child.isFrontendRoute ? (
                  <Link to={child.url || ''}>{child.label}</Link>
                ) : (
                  <a href={child.url}>{child.label}</a>
                )}
              </Menu.Item>
            );
          }
          return null;
        })}

        {(navbarRight.version_string || navbarRight.version_sha) && [
          <Menu.Divider key="version-info-divider" />,
          <Menu.ItemGroup key="about-section" title={t('About')}>
            <div className="about-section">
              {navbarRight.show_watermark && (
                <div css={versionInfoStyles}>
                  {t('Powered by Apache Superset')}
                </div>
              )}
              {navbarRight.version_string && (
                <div css={versionInfoStyles}>
                  {t('Version')}: {navbarRight.version_string}
                </div>
              )}
              {navbarRight.version_sha && (
                <div css={versionInfoStyles}>
                  {t('SHA')}: {navbarRight.version_sha}
                </div>
              )}
              {navbarRight.build_number && (
                <div css={versionInfoStyles}>
                  {t('Build')}: {navbarRight.build_number}
                </div>
              )}
            </div>
          </Menu.ItemGroup>,
        ]}
      </SubMenu>
    );
  };

  const theme = useTheme();

  return (
    <StyledDiv align={align}>
      {/* App Environment */}
      {environmentTag?.text && (
        <Label
          css={{ borderRadius: `${theme.gridUnit * 125}px` }}
          color={
            /^#(?:[0-9a-f]{3}){1,2}$/i.test(environmentTag.color)
              ? environmentTag.color
              : environmentTag.color
                  .split('.')
                  .reduce((o, i) => o[i], theme.colors)
          }
        >
          <span css={tagStyles}>{environmentTag.text}</span>
        </Label>
      )}
      {/* Subscription */}
      {subscription ? (
        <SubscriptionTooltip>
          <Label
            css={{ borderRadius: `${theme.gridUnit * 10}px` }}
            color={theme.colors.primary.base}
          >
            <span css={tagStyles}>
              <i className="fa-solid fa-crown" />{' '}
              {subscription.subscription_plan?.name}
            </span>
          </Label>
        </SubscriptionTooltip>
      ) : organization &&
        environmentTag?.text?.toLowerCase().includes('dev') ? (
        <Button
          style={{
            color: theme.colors.primary.dark1,
            backgroundColor: theme.colors.info.light1,
          }}
          onClick={() => {
            if (organization)
              window.location.assign(
                `${window.location.protocol}//${window.location.host}/organization/${organization?.id}/#subscription`,
              );
          }}
        >
          <i className="fa-solid fa-hand-point-right fa-beat fa-lg" />{' '}
          {t('Upgrade')}
        </Button>
      ) : null}

      <Menu
        selectable={false}
        mode="horizontal"
        disabledOverflow
        // onClick={handleMenuSelection}
        // onOpenChange={onMenuOpen}
      >
        {RightMenuExtension && <RightMenuExtension />}
        {right_menu?.map((item, index) => {
          const props = {
            index,
            ...item,
            isFrontendRoute: isFrontendRoute(item.url),
            childs: item.childs?.map(c => ({
              ...c,
              isFrontendRoute: isFrontendRoute(c.url),
            })),
          };

          return renderSubMenu(props);
        })}

        {navbarRight.show_language_picker && (
          <LanguagePicker
            locale={navbarRight.locale}
            languages={navbarRight.languages}
          />
        )}
      </Menu>
      {navbarRight.documentation_url && (
        <>
          <StyledAnchor
            href={navbarRight.documentation_url}
            target="_blank"
            rel="noreferrer"
            title={navbarRight.documentation_text || t('Documentation')}
          >
            {navbarRight.documentation_icon ? (
              <i className={navbarRight.documentation_icon} />
            ) : (
              <i className="fa fa-question" />
            )}
          </StyledAnchor>
          <span>&nbsp;</span>
        </>
      )}
      {navbarRight.bug_report_url && (
        <>
          <StyledAnchor
            href={navbarRight.bug_report_url}
            target="_blank"
            rel="noreferrer"
            title={navbarRight.bug_report_text || t('Report a bug')}
          >
            {navbarRight.bug_report_icon ? (
              <i className={navbarRight.bug_report_icon} />
            ) : (
              <i className="fa fa-bug" />
            )}
          </StyledAnchor>
          <span>&nbsp;</span>
        </>
      )}
      {navbarRight.user_is_anonymous && (
        <StyledAnchor href={navbarRight.user_login_url}>
          <i className="fa fa-fw fa-sign-in" />
          {t('Login')}
        </StyledAnchor>
      )}
    </StyledDiv>
  );
};

const RightMenuWithQueryWrapper: React.FC<Props> = props => (
  <RightMenu {...props} />
);

// Query param manipulation requires that, during the setup, the
// QueryParamProvider is present and configured.
// Superset still has multiple entry points, and not all of them have
// the same setup, and critically, not all of them have the QueryParamProvider.
// This wrapper ensures the RightMenu renders regardless of the provider being present.
class RightMenuErrorWrapper extends React.PureComponent<Props> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  noop = () => {};

  render() {
    if (this.state.hasError) {
      return <RightMenu {...this.props} />;
    }

    return this.props.children;
  }
}

const RightMenuWrapper: React.FC<Props> = props => (
  <RightMenuErrorWrapper {...props}>
    <RightMenuWithQueryWrapper {...props} />
  </RightMenuErrorWrapper>
);

export default RightMenuWrapper;
